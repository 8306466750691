import { Module } from "vuex";
import { IRootState } from "@monorepo/inventory/src/store";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { checkExistLibrary, encodeQueryData, getFullPath, getQuery } from "@monorepo/utils/src/api/utils";
import { convertFiltersCustomToApi } from "@monorepo/inventory/src/views/HandoverView/utils/convertFiltersToApi";
import { IHandoverElement } from "@monorepo/inventory/src/views/HandoverView/types/element";
import { getSelectedIds } from "@monorepo/utils/src/utils/getSelectedIds";
import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { convertFiltersToApi, IConvertFiltersToApiResponse } from "@monorepo/utils/src/api/convertFiltersToApi";
import { mutations as baseMutations } from "@monorepo/utils/src/store/modules/mutations";
import { actions as baseActions } from "@monorepo/utils/src/store/modules/actions";
import { getters as baseGetters } from "@monorepo/utils/src/store/modules/getters";
import { ISection, Sections } from "@monorepo/utils/src/types/cellsSections";
import { ISearchTemplate } from "@monorepo/utils/src/types/ISearchTemplate";
import { SORT_TYPE } from "@monorepo/utils/src/types/sortTypes";
import { IOikElement } from "@monorepo/catalog/src/views/OikView/types/oikElement";
import axios from "axios";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { elementStatuses } from "@monorepo/utils/src/variables/elementStatuses";
import moment from "moment";

interface ITableState {
  filters: ITableFiltersObject;
  totalLength: number;
  section?: Sections;
  data: IHandoverElement[];
  sectionCells: ISection;
  isOpenFilters: boolean;
  searchTemplates: ISearchTemplate[];
  cells: string[];
  infiniteId: string;
  isTableLoading: boolean;
  libraries: {
    years: string[];
  };
}

const defaultFilters = (): ITableFiltersObject => ({
  sort: {
    code: SORT_TYPE.ASC,
  },
  openedId: null,
  isSelectAll: false,
  selectedIds: {},
  fieldFilters: {
    status: [elementStatuses[0].value],
    year: new Date().getFullYear().toString(),
  },
  initMessagesLength: 50,
});

const getDefaultState = (): ITableState => ({
  filters: defaultFilters(),
  infiniteId: new Date().toString(),
  sectionCells: { id: 7 } as unknown as ISection,
  totalLength: 0,
  searchTemplates: [],
  cells: [],
  section: Sections.CASE_TRANSFER,
  data: [],
  isOpenFilters: false,
  isTableLoading: false,
  libraries: {
    years: [],
  },
});

export const handoverView: Module<ITableState, IRootState> = {
  namespaced: true,
  state: getDefaultState,
  mutations: {
    ...baseMutations,
    addSort(state: ITableState, payload: Record<string, SORT_TYPE>) {
      state.filters.sort = { ...payload };
    },
    setFilters(state: ITableState, payload: ReturnType<typeof defaultFilters>) {
      state.filters = payload;
    },
    resetState(state: ITableState) {
      Object.assign(state, getDefaultState());
    },
    clearFilters(state: ITableState) {
      state.filters = defaultFilters();
    },
    updateHandoverItem(state: ITableState, payload: { itemId: number; from: number; to: number; id: number }) {
      state.data = state.data.map((item) => {
        if (item.id === payload.itemId) {
          return {
            ...item,
            transferHistory: item.transferHistory.map((history) => {
              if (+history.id === payload.id) {
                return {
                  ...history,
                  caseTransferEndDate: moment(payload.to).format("YYYY-MM-DD"),
                  caseTransferStartDate: moment(payload.from).format("YYYY-MM-DD"),
                };
              }
              return history;
            }),
          };
        }
        return item;
      });
    },
    addData(
      state: ITableState,
      payload: {
        data: IHandoverElement[];
        totalLength: number;
        isReset: boolean;
      }
    ) {
      state.data = payload.isReset ? payload.data : state.data.concat(payload.data);
      state.totalLength = payload.totalLength;
    },
    setTableLoading(state: ITableState, payload: boolean) {
      state.isTableLoading = payload;
    },
    addYearsLib(state: ITableState, payload: string[]) {
      state.libraries.years = payload || [];
    },
  },
  actions: {
    ...baseActions,
    updateHandoverItem(state, payload) {
      state.commit("updateHandoverItem", payload);
    },
    async notifyOik(state, id: string) {
      try {
        await axios.get(QUERY_PATH.GET_OIK_NOTIFY + `/${id}`);
        return true;
      } catch (e) {
        return false;
      }
    },
    async getEventList({ commit, state, rootGetters }) {
      if (rootGetters["auth/isShowAnimation"]) {
        commit("setTableLoading", true);
      }
      const params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi);
      const {
        data,
        total: totalLength,
        error,
      } = await getQuery<IOikElement[]>(QUERY_PATH.GET_CATALOG_OIK_LIST, params).finally(() => {
        commit("setTableLoading", false);
      });
      commit("setTableLoading", false);
      if (data !== null) {
        commit("addData", { data: data || [], totalLength: totalLength || 0 });
        return { data: state.data || [], totalLength: totalLength || 0 };
      }
      return { data: null, error };
    },
    async refreshEventList({ commit, state }) {
      const params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi);
      const { data, total: totalLength } = await getQuery<IOikElement[]>(QUERY_PATH.GET_CATALOG_OIK_LIST, { ...params, offset: 0 });
      if (data !== null) {
        commit("addData", { data: data || [], totalLength: totalLength || 0, isReset: true });
      }
      return { data: null };
    },
    async saveHandoverPeriod(
      info,
      {
        event,
        id,
        ...params
      }: {
        event: IOikElement;
        id?: number;
        caseTransferStartDate: string;
        caseTransferEndDate: string;
      }
    ): Promise<boolean> {
      try {
        await axios[id ? "put" : "post"](getFullPath(QUERY_PATH.SAVE_OIK_HANDOVER_PERIOD), {
          ...params,
          ...(id ? { id: id } : { oikId: event.id }),
        }).catch(function (error) {
          showNotification(error.response.data.message);
          return Promise.reject(error);
        });
        return true;
      } catch (e) {
        return false;
      }
    },
    async getYears({ commit, state }) {
      checkExistLibrary(state.libraries.years, async () => {
        const { data } = await getQuery<string[]>(QUERY_PATH.GET_OIK_YEARS_LIB);
        commit("addYearsLib", data);
      });
    },
    async getExportData({ state, dispatch }, payload: "CSV" | "PDF") {
      let params: IConvertFiltersToApiResponse | { ids: string[] };
      const selectedKeys = state.filters.selectedIds ? getSelectedIds(state.filters.selectedIds) : [];
      if (selectedKeys.length && !state.filters.isSelectAll) {
        params = {
          ids: selectedKeys,
        };
      } else {
        params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi, true);
      }
      const queryParams = encodeQueryData(params);
      dispatch("app/openNewWindow", getFullPath(payload === "CSV" ? QUERY_PATH.GET_HANDOVER_OIK_CSV : QUERY_PATH.GET_HANDOVER_OIK_PDF, queryParams), {
        root: true,
      });
    },
  },
  getters: {
    ...baseGetters,
    isTableLoading(state: ITableState) {
      return state.isTableLoading;
    },
    yearsLibrary(state: ITableState) {
      return state.libraries.years;
    },
  },
};
