































































import InfiniteScroll from "@monorepo/uikit/src/components/tableViews/InfiniteScroll.vue";
import Table from "@monorepo/inventory/src/views/HandoverView/components/Table.vue";
import SortPanelContainer from "@monorepo/uikit/src/components/common/SortPanelContainer.vue";
import TableHeader from "@monorepo/uikit/src/components/tableViews/TableHeader.vue";
import Filters from "@monorepo/inventory/src/views/HandoverView/components/Filters.vue";
import TableSettings from "@monorepo/uikit/src/components/tableViews/TableSettings.vue";
import Breadcrumbs from "@monorepo/uikit/src/components/common/Breadcrumbs.vue";
import ScrollPanel from "@monorepo/uikit/src/components/tableViews/ScrollPanel.vue";
import Footer from "@monorepo/uikit/src/components/common/Footer.vue";
import { tableHeaders } from "@monorepo/utils/src/variables/projectsData/handoverView/tableHeaders";
import { viewTitle, viewUniqKey } from "@monorepo/utils/src/variables/projectsData/handoverView/viewTitle";
import { defineComponent, ref } from "@vue/composition-api";
import useSetStartFilters from "@monorepo/utils/src/composables/useSetStartFilters";
import { mapActions, mapGetters, Module } from "vuex";
import { breadcrumbs } from "@monorepo/inventory/src/views/HandoverView/constants/breadcrumbs";
import useInitTableStoreModule from "@monorepo/utils/src/store/composables/useInitTableStoreModule";
import { handoverView } from "@monorepo/inventory/src/store/modules/handoverView";
import useInitEducationPanel from "@monorepo/utils/src/composables/useInitEducationPanel";
import useSetStartModal from "@monorepo/utils/src/composables/useSetStartModal";
import useOpenHistoryMode from "@monorepo/utils/src/composables/useOpenHistoryMode";
import Gant from "@monorepo/inventory/src/views/HandoverView/components/Gant.vue";
import GantTooltipBtn from "@monorepo/inventory/src/views/HandoverView/components/GantTooltipBtn.vue";
import { fields } from "@monorepo/utils/src/variables/projectsData/handoverView/fields";
import useGetTableLibs from "@monorepo/inventory/src/views/HandoverView/composables/useGetTableLibs";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import { educationIds } from "@monorepo/utils/src/variables/educationIds";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import ExportFooterButton from "@monorepo/uikit/src/components/tableViews/ExportFooterButton.vue";

const SECTION_NAME = "handoverView";

const defaultTooltipPosition = () => ({ left: -1000, top: -1000 });

export default defineComponent({
  name: "HandoverView",
  components: {
    TableSettings,
    Table,
    Gant,
    Filters,
    InfiniteScroll,
    GantTooltipBtn,
    ScrollPanel,
    TableHeader,
    Breadcrumbs,
    SortPanelContainer,
    Footer,
    ExportFooterButton,
  },
  data() {
    return {
      breadcrumbs,
      viewTitle,
      educationIds,
      isShowTooltip: false,
      tooltipPosition: defaultTooltipPosition(),
      viewUniqKey,
      tableHeaders,
      fields,
      pluralFormTitles: ["График передачи дел", "Графика передачи дел", "Графиков передачи дел"],
      monthList: ["янв.", "февр.", "март", "апр.", "май", "июнь", "июль", "авг.", "сент.", "окт.", "нояб.", "дек."],
    };
  },
  computed: {
    ...mapGetters("auth", ["isOpenEducation", "user"]),
    ...mapGetters(SECTION_NAME, [
      "cells",
      "isOpenFilters",
      "data",
      "openedId",
      "isSelectAll",
      "infiniteId",
      "totalLength",
      "selectedIds",
      "isTableLoading",
    ]),
    isShowExport(): boolean {
      return isAuthorityExist(this.user, authorities.OIK_CASE_TRANSFER_EXPORT);
    },
    isShowFooter(): boolean {
      return (this.isSelectAllElement || !!Object.values(this.selectedIds).filter(Boolean).length) && this.isShowExport;
    },
    isShowModal(): boolean {
      return !this.isOpenEducation && Boolean(this.openedId);
    },
    isSelectAllElement: {
      get(): boolean {
        return this.isSelectAll;
      },
      set(value: boolean) {
        this.$store.dispatch(`${SECTION_NAME}/setIsSelectAll`, value);
      },
    },
  },
  methods: {
    ...mapActions(SECTION_NAME, ["getEventList", "getExportData", "addOpenedId", "setIsOpenFilters"]),
    toggleTooltip(position: { id: string; isNew: boolean; left: number; top: number }) {
      this.isShowTooltip = !position.isNew ? !this.isShowTooltip : true;
      this.tooltipPosition = this.isShowTooltip ? position : defaultTooltipPosition();
    },
    toggleModal(value: boolean) {
      this.showModal = value;
      if (!value) {
        this.$store.dispatch(`${SECTION_NAME}/addOpenedId`, null);
      }
    },
    setCells(value: Record<string, unknown>[]) {
      this.$store.dispatch(
        `${SECTION_NAME}/setCells"`,
        value.map((item) => item.value)
      );
    },
  },
  setup(props, { root }) {
    const filtersLoaded = ref(false);
    const showModal = ref(false);
    const isNeedRefresh = ref(true);
    const sectionName = ref(SECTION_NAME); // sectionName === store module name

    useInitTableStoreModule(root, SECTION_NAME, handoverView as Module<unknown, unknown>);
    useOpenHistoryMode(root, SECTION_NAME);
    useSetStartFilters(root, SECTION_NAME, []).then(() => {
      useInitEducationPanel(root);
      filtersLoaded.value = true;
      useSetStartModal(root, SECTION_NAME);
      if (root.$store.getters[`${SECTION_NAME}/openedId`]) {
        showModal.value = true;
      }
    });
    useGetTableLibs(root);

    return {
      filtersLoaded,
      showModal,
      isNeedRefresh,
      sectionName,
    };
  },
});
