









import { defineComponent } from "@vue/composition-api";
import { environmentVariables } from "@monorepo/utils/src/variables/environmentVariables";
import { mapActions } from "vuex";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";

const SECTION_NAME = "handoverView";

export default defineComponent({
  name: "GantTooltipBtn",
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      attachArrow: `${environmentVariables.BASE_URL}assets/images/oik/attachArrow.svg`,
      isLoadingSync: false,
    };
  },
  methods: {
    ...mapActions(SECTION_NAME, ["notifyOik"]),
    async synchronize() {
      try {
        this.isLoadingSync = true;
        const isSend = await this.notifyOik(this.id);

        if (isSend) {
          showNotification("Отправлено оповещение на электронную почту", NOTIFICATION_STATUS.SUCCESS);
          this.$emit("close", { isNew: false });
        }
      } catch (e) {
        console.error(e);
        // showNotification("Ошибка отправки оповещения", NOTIFICATION_STATUS.ERROR);
      } finally {
        this.isLoadingSync = false;
      }
    },
  },
});
