export const breadcrumbs = [
  {
    text: "Главная",
    disabled: false,
    href: "/",
  },
  {
    text: "Учет: График передачи дел",
    disabled: true,
    href: "breadcrumbs_link_1",
  },
];
