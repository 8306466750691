









































































import { defineComponent, ref } from "@vue/composition-api";
import { educationIds } from "@monorepo/utils/src/variables/educationIds";
import { currentFilters as setCurrentFilters } from "../utils/defaultCurrentFilters";
import SelectFilter from "@monorepo/uikit/src/components/common/Select/SelectFilter.vue";
import TextFilter from "@monorepo/uikit/src/components/tableViews/TextFilter.vue";
import DatePicker from "@monorepo/uikit/src/components/tableViews/DatePicker.vue";
import TimePicker from "@monorepo/uikit/src/components/tableViews/TimePicker.vue";
import FindButton from "@monorepo/uikit/src/components/tableViews/FindButton.vue";
import ToggleFilters from "@monorepo/uikit/src/components/tableViews/ToggleFilters.vue";
import ClearFilters from "@monorepo/uikit/src/components/tableViews/ClearFilters.vue";
import Autocomplete from "@monorepo/uikit/src/components/tableViews/Autocomplete.vue";
import FilterLabel from "@monorepo/uikit/src/components/tableViews/FilterLabel.vue";
import { mapActions, mapGetters } from "vuex";
import { viewUniqKey } from "@monorepo/utils/src/variables/projectsData/handoverView/viewTitle";
import { filtersElements } from "@monorepo/utils/src/variables/projectsData/handoverView/filtersElements";
import { fields } from "@monorepo/utils/src/variables/projectsData/handoverView/fields";
import { defaultFilterPlaceholder } from "@monorepo/utils/src/constants";
import YearPicker from "@monorepo/uikit/src/components/tableViews/YearPicker.vue";
import { elementStatuses } from "@monorepo/utils/src/variables/elementStatuses";
import { convertToSelectItems } from "@monorepo/utils/src/utils/convertToSelectItems";
import useTemplateFilters from "@monorepo/utils/src/composables/useTemplateFilters";
import useFilter from "@monorepo/utils/src/composables/useFilter";

export default defineComponent({
  components: {
    TextFilter,
    FilterLabel,
    DatePicker,
    YearPicker,
    TimePicker,
    SelectFilter,
    Autocomplete,
    FindButton,
    ToggleFilters,
    ClearFilters,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    sectionName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      educationIds,
      filtersElements,
      viewUniqKey,
      fields,
      defaultFilterPlaceholder,
      elementStatuses,
      defaultYearItems: [{ title: new Date().getFullYear().toString(), value: new Date().getFullYear().toString() }],
    };
  },
  computed: {
    ...mapGetters("handoverView", ["fieldFilters", "searchTemplates", "isTableLoading", "yearsLibrary"]),
    periodFrom: {
      get(): string {
        return this.currentFilters[fields.HANDOVER_PERIOD_FROM] as string;
      },
      set(value: string): void {
        this.currentFilters[fields.HANDOVER_PERIOD_FROM] = value;
      },
    },
    years(): { title: string; value: string }[] {
      return convertToSelectItems(this.yearsLibrary.map((year: number) => year.toString()));
    },
    year: {
      get(): string {
        return this.currentFilters[fields.HANDOVER_YEAR] as string;
      },
      set(value: string): void {
        if (!value.length) {
          this.currentFilters[fields.HANDOVER_YEAR] = this.defaultYearItems[0].value;
        } else {
          this.currentFilters[fields.HANDOVER_YEAR] = value;
        }
      },
    },
    status: {
      get(): string[] {
        return this.currentFilters[fields.OIK_STATUS] as string[];
      },
      set(value: string[]): void {
        if (!value.length) {
          this.currentFilters[fields.OIK_STATUS] = [elementStatuses[0].value];
        } else {
          this.currentFilters[fields.OIK_STATUS] = value;
        }
      },
    },
  },
  methods: {
    ...mapActions("handoverView", ["clearFilters", "addFilter", "getOikAutocompleteItems", "saveSearchTemplates"]),
    ...mapActions("handoverView", ["refreshScroll", "getTaskAutocompleteByTitle", "getTaskAutocompleteByNumber"]),
    setCurrentFilters,
  },
  setup(props, context) {
    const { root } = context;
    const currentFilters = ref((setCurrentFilters as () => Record<string, unknown>)());
    const notHiddenKeys = ref([] as string[]);
    const isShowToggle = ref(false);
    const autocompleteItemsResultLists = ref({} as { [key: string]: Record<string, unknown>[] });
    const isLoadingAutocomplete = ref(false);
    useTemplateFilters(root, props.sectionName, currentFilters);
    const { filter, hiddenRowsStyleObj, openHiddenFilterOnStart, toggleOpenFilter, clearFiltersCb, refresh, updateFiltersDivHeight } = useFilter(
      context,
      props as {
        value: boolean;
      },
      props.sectionName,
      {},
      currentFilters,
      setCurrentFilters,
      filtersElements as { value: string; title: string }[],
      notHiddenKeys,
      isShowToggle
    );

    return {
      filter,
      hiddenRowsStyleObj,
      openHiddenFilterOnStart,
      toggleOpenFilter,
      clearFiltersCb,
      refresh,
      updateFiltersDivHeight,
      currentFilters,
      notHiddenKeys,
      isShowToggle,
      autocompleteItemsResultLists,
      isLoadingAutocomplete,
    };
  },
});
