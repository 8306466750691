import moment, { Moment } from "moment";
import { IHandoverElement } from "@monorepo/inventory/src/views/HandoverView/types/element";
import { SvelteGanttTable, MomentSvelteGanttDateAdapter } from "svelte-gantt";

export type Tasks = { task: { model: { id: number; from: number; to: number } } };
export type GantApi = { tasks?: { on: { change: (cb: (tasks: Tasks[]) => void) => void } } };
export type DayRangeItem = { id: number; from: number; to: number; classes: string; label: string };

function shuffle(array: string[] | number[]): void {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

function formRow(item: { id: number }, selectedIds: Record<string, boolean>, isCanDrag: boolean, year: string, generation: number) {
  return {
    id: item.id,
    label: "Row #" + item.id,
    enableDragging: isCanDrag && year === moment().format("YYYY"),
    age: (Math.random() * 80) | 0,
    height: 48,
    imageSrc: "Content/joe.jpg",
    classes: selectedIds[item.id.toString()] ? ["row-selected"] : undefined,
    generation,
    y: 100,
  };
}

function formTask(
  item: { id: number },
  periodLabel: string,
  transferDates: Record<string, string>,
  selectedIds: Record<string, boolean>,
  generation: number,
  dateToOik: number
) {
  const endMoment = moment(transferDates?.caseTransferEndDate, "YYYY-MM-DD");
  const isRed = endMoment.isAfter(moment()) && endMoment.isBefore(moment().add(dateToOik, "day"));
  return {
    type: "task",
    id: item.id,
    resourceId: item.id,
    label: periodLabel || "",
    from: moment()
      .clone()
      .startOf("year")
      .add(transferDates?.caseTransferStartDate ? new Date(transferDates.caseTransferStartDate).getMonth() : 0, "month")
      .add(transferDates?.caseTransferStartDate ? new Date(transferDates.caseTransferStartDate).getDate() : 1, "day"),
    to: moment()
      .clone()
      .startOf("year")
      .add(transferDates?.caseTransferEndDate ? new Date(transferDates.caseTransferEndDate).getMonth() : 2, "month")
      .add(transferDates?.caseTransferEndDate ? new Date(transferDates.caseTransferEndDate).getDate() : 31, "day"),
    classes: `gant-green ${isRed ? "gant-red" : ""} ${selectedIds[item.id.toString()] ? "selected-task" : ""}`,
    generation,
  };
}

type GenerateType = {
  rows: {
    id: number;
    label: string;
    enableDragging: boolean;
    age: number;
    height: number;
    imageSrc: string;
    classes: string[] | undefined;
    generation: number;
    y: number;
  }[];
  tasks: {
    type: string;
    id: number;
    resourceId: number;
    label: string;
    from: moment.Moment;
    to: moment.Moment;
    classes: string;
    generation: number;
  }[];
};

export function generate(
  data: IHandoverElement[],
  selectedIds: Record<string, boolean> = {},
  year: string,
  dateToOik: number,
  isCanDrag = true
): GenerateType {
  let generation = 0;
  const rowCount = data?.length || 0;

  const rows = [];
  const tasks = [];

  const ids = [...Array(rowCount).keys()];
  shuffle(ids);

  for (const item of data) {
    const transferDates = item.transferHistory?.find(
      (dates: Record<string, string>) => new Date(dates.caseTransferStartDate).getFullYear().toString() === year
    );
    const periodLabel = transferDates
      ? (transferDates?.caseTransferStartDate ? moment(transferDates.caseTransferStartDate).format("DD.MM") : "") +
        " - " +
        (transferDates?.caseTransferEndDate ? moment(transferDates.caseTransferEndDate).format("DD.MM") : "")
      : "";
    rows.push(formRow(item, selectedIds, isCanDrag, year, generation));
    if (transferDates) {
      tasks.push(formTask(item, periodLabel, transferDates, selectedIds, generation, dateToOik));
    }
  }

  generation += 1;

  return { rows, tasks };
}

export function addCurrentDayRange(): {
  id: number;
  from: Moment;
  to: Moment;
  classes: string;
  label: string;
}[] {
  return [
    {
      id: 3,
      from: moment(),
      to: moment(),
      classes: "current-day",
      label: "Dinner",
    },
  ];
}

export function addPeriodRanges(
  periodFrom: string,
  periodTo: string
): {
  id: number;
  from: Moment;
  to: Moment;
  classes: string;
  label: string;
}[] {
  return [
    {
      id: 0,
      from: moment().startOf("year"),
      to: moment(periodFrom, "YYYY-MM-DD"),
      classes: "range_shadow",
      label: "Lunch",
    },
    {
      id: 1,
      from: moment(periodFrom, "YYYY-MM-DD"),
      to: moment(periodTo, "YYYY-MM-DD"),
      classes: "range",
      label: "Lunch",
    },
    {
      id: 2,
      from: moment(periodTo, "YYYY-MM-DD"),
      to: moment().endOf("year"),
      classes: "range_shadow",
      label: "Lunch",
    },
  ];
}

export function getFromToPeriodGant(year: string): {
  from: Moment;
  to: Moment;
} {
  const currentStart = moment(year, "YYYY").clone().startOf("year");
  const currentEnd = moment(year, "YYYY").clone().endOf("year");
  return {
    from: currentStart,
    to: currentEnd,
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function formBaseOptionsAttributes() {
  return {
    dateAdapter: new MomentSvelteGanttDateAdapter(moment),
    rows: [],
    tasks: [],
    columnUnit: "month",
    columnOffset: 1,
    rowHeight: 49,
    rowPadding: 8,
    headers: [{ unit: "month", format: "MM" }],
    fitWidth: true,
    minWidth: 100,
    ...getFromToPeriodGant(moment().format("YYYY")),
    timeRanges: [...addCurrentDayRange()],
    tableHeaders: [],
    tableWidth: 240,
    ganttTableModules: [SvelteGanttTable],
  };
}
