var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-simple-table',{staticClass:"monitoring-table monitoring-table__content",attrs:{"id":"virtual-scroll-table"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((_vm.resultData),function(event){return _c('tr',{key:event[_vm.fields.ID],class:{
          'monitoring-table__tr-initial': !_vm.isShowAnimation,
          'monitoring-table__tr monitoring-table__tr-animation': _vm.isShowAnimation,
          'monitoring-table__tr-animation-active': _vm.selectedIdsElements[event[_vm.fields.ID]] || _vm.openedId === event[_vm.fields.ID],
        },attrs:{"data-test-id":"tableRow"},on:{"mousemove":_vm.addAnimation,"mouseleave":_vm.clearAnimation}},_vm._l((_vm.headers),function(header){return _c('td',{key:("" + (event[_vm.fields.ID]) + (header.id)),class:"monitoring-table__td pl-3 pr-3 pt-2 pb-2",style:({ width: header.width ? ((header.width) + "px") : '' }),attrs:{"data-test-id":"tableCell"}},[(header.value === _vm.headerCheckboxValue)?_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"data-test-id":"tableCellCheckbox","ripple":false,"input-value":_vm.selectedIdsElements[event[_vm.fields.ID]]},on:{"click":function($event){$event.preventDefault();return _vm.clickCheckbox(event[_vm.fields.ID])}}}):(header.value === _vm.fields.OIK_NAME)?_c('v-tooltip',{attrs:{"z-index":"200","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"table__oik-name"},'div',attrs,false),on),[_vm._v(_vm._s(event[header.value]))])]}}],null,true)},[_c('div',[_vm._v(_vm._s(event.name))])]):(header.value === _vm.fields.HANDOVER_PERIOD)?_c('div',{staticClass:"d-flex flex-row justify-center"},[(event[_vm.fields.HANDOVER_PERIOD_FROM])?_c('span',{staticClass:"table__modal-link",on:{"click":function($event){return _vm.openSetPeriodModal(event)}}},[_vm._v(_vm._s(event[header.value]))]):(_vm.isShowModifyBtn)?_c('v-btn',{staticClass:"table__open-modal-btn",attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.openSetPeriodModal(event)}}},[_vm._v("Задать")]):_vm._e()],1):_c('span',[_vm._v(_vm._s(event[header.value]))])],1)}),0)}),0)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }