export interface IHandoverFields {
  ID: string;
  HANDOVER_YEAR: string;
  HANDOVER_PERIOD: string;
  HANDOVER_PERIOD_FROM: string;
  HANDOVER_PERIOD_TO: string;
  OIK_STATUS: string;
  OIK_ID: string;
  OIK_CODE: string;
  OIK_NAME: string;
}

export const fields: IHandoverFields = {
  ID: "id",
  HANDOVER_YEAR: "year",
  HANDOVER_PERIOD: "period",
  HANDOVER_PERIOD_FROM: "caseTransferStartDate",
  HANDOVER_PERIOD_TO: "caseTransferEndDate",
  OIK_STATUS: "status",
  OIK_ID: "oikId",
  OIK_CODE: "code",
  OIK_NAME: "name",
};
