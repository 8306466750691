import { fields } from "./fields";

export const filtersElements = [
  {
    value: fields.HANDOVER_YEAR,
    title: "Год",
    tooltip: "Выбор даты в окне календаря, после нажатия на поле фильтра",
  },
  {
    value: fields.HANDOVER_PERIOD,
    title: "Период",
    tooltip: "Выбор даты в окне календаря, после нажатия на поле фильтра",
  },
  {
    value: fields.OIK_STATUS,
    title: "Состояние ОИК",
    tooltip: "Выпадающий список",
  },
];
