




import { defineComponent } from "@vue/composition-api";
import { mapActions, mapGetters } from "vuex";
import { SvelteGantt } from "svelte-gantt";
import { SvelteGanttComponent, SvelteGanttOptions } from "svelte-gantt/types/gantt";
import moment from "moment";
import { fields } from "@monorepo/utils/src/variables/projectsData/handoverView/fields";
import { IHandoverElement } from "@monorepo/inventory/src/views/HandoverView/types/element";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";
import { convertApiItemToUi } from "@monorepo/inventory/src/views/HandoverView/utils/convertApiItemToUi";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import {
  generate,
  formBaseOptionsAttributes,
  GantApi,
  Tasks,
  addCurrentDayRange,
  getFromToPeriodGant,
  DayRangeItem,
  addPeriodRanges,
} from "../utils/gantHelperFunction";

const SECTION_NAME = "handoverView";

export default defineComponent({
  name: "Gant",
  data() {
    return {
      gant: null as SvelteGanttComponent | null,
      fields,
      dateToOik: 7,
      saveNodeId: null as null | number,
    };
  },
  computed: {
    ...mapGetters("auth", ["isShowAnimation", "user"]),
    ...mapGetters(SECTION_NAME, ["data", "fieldFilters", "selectedIds"]),
    resultData(): IHandoverElement[] {
      return (this.data as IHandoverElement[]).map((item: IHandoverElement) => {
        return convertApiItemToUi(item, (this.fieldFilters as { year: string }).year || new Date().getFullYear().toString());
      });
    },
    isShowModifyBtn(): boolean {
      return isAuthorityExist(this.user, authorities.OIK_CASE_TRANSFER_MODIFY);
    },
  },
  methods: {
    ...mapActions("app", ["getNotificationSettings"]),
    ...mapActions(SECTION_NAME, ["saveHandoverPeriod", "updateHandoverItem"]),
    async formStartSetting() {
      const dateToOik: number = await this.getNotificationSettings("NOTIFY_OIK_BEFORE_DAYS_TO_END");
      this.dateToOik = dateToOik;
    },
    formHandoverPeriodData(event: { id: number; from: number; to: number }) {
      const item: { transferHistory: { id: string }[]; id: number } = this.data.find((item: { id: number }) => item.id === event.id);
      const selectedYear: string = this.fieldFilters[fields.HANDOVER_YEAR];
      const transferDates = item.transferHistory?.find(
        (dates: Record<string, string>) => new Date(dates.caseTransferStartDate).getFullYear().toString() === selectedYear
      );
      return { item, transferDates, selectedYear };
    },
    async handleSaveHandoverPeriod({ transferDates, event }: { transferDates?: { id: string }; event: { id: number; from: number; to: number } }) {
      try {
        const isSaved = await this.saveHandoverPeriod({
          event,
          id: transferDates?.id,
          caseTransferStartDate: moment(event.from).format("YYYY-MM-DD"),
          caseTransferEndDate: moment(event.to).format("YYYY-MM-DD"),
        });
        return isSaved;
      } catch (e) {
        return false;
      }
    },
    async cbOnSetPeriod(event: { id: number; from: number; to: number }) {
      const { item, transferDates } = this.formHandoverPeriodData(event);
      if (!moment(event.from).isSame(moment(event.to), "year")) {
        showNotification("Дата начала и конца периода должна относится к одному году");
        const elements = generate(
          this.resultData || [],
          this.selectedIds,
          this.fieldFilters[this.fields.HANDOVER_YEAR],
          this.dateToOik,
          this.isShowModifyBtn
        );
        this.gant?.$set({
          rows: elements.rows,
          tasks: elements.tasks,
        });
        return;
      }
      const isSaved = await this.handleSaveHandoverPeriod({
        transferDates,
        event,
      });
      if (isSaved) {
        this.updateHandoverItem({ itemId: item?.id, from: event.from, to: event.to, id: transferDates?.id });
        showNotification("Данные успешно сохранены", NOTIFICATION_STATUS.SUCCESS);
      }
    },
    formDataOnChangeFilters(filters: Record<string, string>) {
      let timeRanges;
      let timeFromAndTo;

      if (filters[fields.HANDOVER_PERIOD_FROM] && filters[fields.HANDOVER_PERIOD_TO] && this.gant?.$set) {
        const selectedYear = moment(filters[fields.HANDOVER_PERIOD_FROM], "YYYY-MM-DD").format("YYYY");
        timeRanges = [...addCurrentDayRange()].concat(
          addPeriodRanges(filters[fields.HANDOVER_PERIOD_FROM], filters[fields.HANDOVER_PERIOD_TO])
        ) as unknown as DayRangeItem[];
        timeFromAndTo = getFromToPeriodGant(moment().format(selectedYear)) as unknown as { from: number; to: number };
      } else {
        timeRanges = [...addCurrentDayRange()] as unknown as DayRangeItem[];
        timeFromAndTo = getFromToPeriodGant(moment().format("YYYY")) as unknown as { from: number; to: number };
      }

      return {
        timeRanges,
        timeFromAndTo,
      };
    },
    handleOnMoveTask(node: HTMLElement) {
      if (!node.clientWidth) {
        return;
      }
      const textNode = node.children[0];
      const gantNodeLeft = document.getElementById("gant")?.getBoundingClientRect()?.left || 0;
      const elementInfo = node.getBoundingClientRect();
      const startPosition = elementInfo.left - gantNodeLeft;
      textNode.innerHTML = `${moment(this.gant?.columnService.getDateByPosition(startPosition)).format("DD.MM")} - ${moment(
        this.gant?.columnService.getDateByPosition(startPosition + node.clientWidth)
      ).format("DD.MM")}`;
    },
    handleOnClickTask(e: MouseEvent, node: HTMLElement) {
      const id = node.dataset.taskId || 0;
      if (node.classList.contains("gant-red")) {
        const { transferDates } = this.formHandoverPeriodData({ id: +id, from: 0, to: 0 });
        const rect = node.getBoundingClientRect();
        this.$emit("toggleTooltip", {
          id: transferDates?.id,
          isNew: +id !== +(this.saveNodeId as number),
          left: rect.left + (rect.right - rect.left) / 2 - 108,
          top: rect.top - 72,
        });
        this.saveNodeId = +(node.dataset.taskId || "");
      }
    },
    taskElementHook(node: HTMLElement) {
      const config = { attributes: true };

      const observer = new MutationObserver(() => {
        this.handleOnMoveTask(node);
      });

      node.addEventListener("click", (e) => {
        this.handleOnClickTask(e, node);
      });

      observer.observe(node, config);
      return {
        destroy() {
          observer?.disconnect?.();
        },
      };
    },
  },
  async created() {
    await this.formStartSetting();
  },
  mounted() {
    let options = {
      ...formBaseOptionsAttributes(),
      taskElementHook: this.taskElementHook,
    };

    this.gant = new SvelteGantt({ target: this.$refs.gant as HTMLElement, props: options as unknown as SvelteGanttOptions });
    (this.gant.api as GantApi)?.tasks?.on?.change?.((tasks: Tasks[]) => {
      const item = tasks?.[0]?.task?.model;
      this.cbOnSetPeriod(item);
    });
  },
  watch: {
    fieldFilters: {
      immediate: true,
      handler(filters) {
        const { timeRanges, timeFromAndTo } = this.formDataOnChangeFilters(filters);

        this.gant?.$set?.({
          timeRanges,
          ...timeFromAndTo,
        });
      },
    },
    selectedIds: {
      handler(items) {
        const elements = generate(this.resultData || [], items, this.fieldFilters[this.fields.HANDOVER_YEAR], this.dateToOik, this.isShowModifyBtn);
        this.gant?.$set({
          rows: elements.rows,
          tasks: elements.tasks,
        });
      },
    },
    data: {
      handler(items) {
        const elements = generate(items || [], this.selectedIds, this.fieldFilters[this.fields.HANDOVER_YEAR], this.dateToOik, this.isShowModifyBtn);
        this.gant?.$set({
          rows: elements.rows,
          tasks: elements.tasks,
        });
      },
    },
  },
});
