import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { formDateFilter } from "@monorepo/utils/src/api/convertFiltersToApi";
import { convertStatus } from "@monorepo/utils/src/api/convertDeleteStatus";

const convertSortKey = (key: string): string => {
  switch (key) {
    default:
      return key;
  }
};

export const convertFiltersCustomToApi = (resultFilters: ITableFiltersObject): void => {
  convertStatus(resultFilters, "isDeleted");
  formDateFilter(resultFilters, "period");
  resultFilters.sort = Object.fromEntries(Object.entries(resultFilters.sort || {}).map(([key, value]) => [convertSortKey(key), value]));
};
