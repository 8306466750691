import { v4 as uuid } from "uuid";
import { fields } from "./fields";
import { headerCheckboxObject } from "@monorepo/utils/src/variables/headerCheckboxValue";

export const tableHeaders = [
  headerCheckboxObject(),
  {
    text: "Код",
    tooltip: "Код ОИК",
    defaultWidth: 75,
    value: fields.OIK_CODE,
    isSorted: true,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "Наименование ОИК",
    tooltip: "Наименование ОИК",
    defaultWidth: 155,
    value: fields.OIK_NAME,
    isSorted: true,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "Период передачи дел",
    tooltip: "Период передачи дел",
    defaultWidth: 140,
    value: fields.HANDOVER_PERIOD,
    isSorted: true,
    isHandle: false,
    id: uuid(),
  },
];
