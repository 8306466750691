






















































import { defineComponent, computed } from "@vue/composition-api";
import { mapActions, mapGetters } from "vuex";
import { tableHeaders } from "@monorepo/utils/src/variables/projectsData/handoverView/tableHeaders";
import { convertApiItemToUi } from "@monorepo/inventory/src/views/HandoverView/utils/convertApiItemToUi";
import OpenCardButton from "@monorepo/uikit/src/components/tableViews/OpenCardButton.vue";
import IdCell from "@monorepo/uikit/src/components/tableViews/IdCell.vue";
import { viewUniqKey } from "@monorepo/utils/src/variables/projectsData/handoverView/viewTitle";
import { fields } from "@monorepo/utils/src/variables/projectsData/handoverView/fields";
import { headerCheckboxValue } from "@monorepo/utils/src/variables/headerCheckboxValue";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import moment from "moment";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";
import { IHandoverElement } from "@monorepo/inventory/src/views/HandoverView/types/element";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import useTable from "@monorepo/utils/src/composables/useTable";
import useTableCells from "@monorepo/utils/src/composables/useTableCells";
import useTableAnimation from "@monorepo/utils/src/composables/useTableAnimation";

export default defineComponent({
  components: {
    OpenCardButton,
    IdCell,
  },
  data() {
    return {
      tableHeaders,
      viewUniqKey,
      fields,
      headerCheckboxValue,
    };
  },
  computed: {
    ...mapGetters("auth", ["isShowAnimation", "user"]),
    ...mapGetters("handoverView", { getCells: "cells" }),
    ...mapGetters("handoverView", ["data", "sort", "fieldFilters", "isSelectAll", "selectedIds", "openedId", "fieldFilters"]),
    resultData(): ReturnType<typeof convertApiItemToUi>[] {
      return this.data.map((item: IHandoverElement) => {
        return convertApiItemToUi(item, this.fieldFilters.year || new Date().getFullYear().toString);
      });
    },
    isShowModifyBtn(): boolean {
      return isAuthorityExist(this.user, authorities.OIK_CASE_TRANSFER_MODIFY);
    },
  },
  methods: {
    ...mapActions("handoverView", ["addOpenedId", "setSelectedIds", "setIsSelectAll", "saveHandoverPeriod", "refreshScroll"]),
    openSetPeriodModal(event: Record<string, unknown>) {
      if (!this.isShowModifyBtn || this.fieldFilters.year !== moment().format("YYYY")) {
        return;
      }
      eventBus.$emit(
        MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
        formModalPayload(true, "SetPeriodModal", {
          changedPeriod: { periodFrom: event.caseTransferStartDate, periodTo: event.caseTransferEndDate },
          cbOnSetPeriod: (changedPeriod: { periodFrom: string; periodTo: string }) => this.cbOnSetPeriod(event, changedPeriod),
        })
      );
    },
    async cbOnSetPeriod(event: Record<string, unknown>, changedPeriod: { periodFrom: string; periodTo: string }) {
      const selectedYear = this.fieldFilters[fields.HANDOVER_YEAR];
      const transferDates = (event?.transferHistory as IHandoverElement[])?.find(
        (dates) => new Date(dates.caseTransferStartDate || "").getFullYear().toString() === selectedYear
      );
      const isSaved = await this.saveHandoverPeriod({
        event,
        id: transferDates?.id,
        caseTransferStartDate: changedPeriod.periodFrom,
        caseTransferEndDate: changedPeriod.periodTo,
      });
      if (isSaved) {
        this.refreshScroll();
        showNotification("Данные успешно сохранены", NOTIFICATION_STATUS.SUCCESS);
      }
    },
  },
  setup(props, { root }) {
    const resultTableHeaders = computed(() => tableHeaders as unknown[]);
    const { clickCheckbox, isElementDeleted, selectedIdsElements } = useTable(root, "handoverView");
    const { headers } = useTableCells(root, "handoverView", resultTableHeaders);
    const { clearAnimation, addAnimation } = useTableAnimation(root);
    return {
      clickCheckbox,
      isElementDeleted,
      selectedIdsElements,
      headers,
      clearAnimation,
      addAnimation,
    };
  },
});
