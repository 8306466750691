import { IHandoverElement } from "@monorepo/inventory/src/views/HandoverView/types/element";
import { fields } from "@monorepo/utils/src/variables/projectsData/handoverView/fields";
import moment from "moment";

export const convertApiItemToUi = (element: IHandoverElement, year: string): IHandoverElement => {
  const currentYearCaseTransfer = element.transferHistory.find((transferObj: Record<string, string>) => {
    return new Date(transferObj.caseTransferStartDate).getFullYear().toString() === year;
  });
  return {
    ...element,
    [fields.HANDOVER_PERIOD_FROM]: currentYearCaseTransfer?.caseTransferStartDate || "",
    [fields.HANDOVER_PERIOD_TO]: currentYearCaseTransfer?.caseTransferEndDate || "",
    [fields.HANDOVER_PERIOD]:
      (currentYearCaseTransfer?.caseTransferStartDate ? moment(currentYearCaseTransfer.caseTransferStartDate).format("DD.MM") : "") +
      " - " +
      (currentYearCaseTransfer?.caseTransferEndDate ? moment(currentYearCaseTransfer.caseTransferEndDate).format("DD.MM") : ""),
  };
};
